import './module/hamburger.js';
import './module/modern-css-reset.js';
import './module/nav.js';
import './module/new-swiper.js';

import $ from "jquery";

$('.js-accordionHeader').on('click', (event) => {
   $(event.currentTarget).toggleClass('-active');
   $(event.currentTarget).next('.js-accordionContent').slideToggle();
   return false;
});
