import $ from "jquery";

// ハンバーガーメニュー
const active = '-active';
$('#js-hamburger').on('click', (event) => {
  $('#js-hamburgerInner').toggleClass('-active');
  $('.js-hamburgerLine').toggleClass('-active');
  $('#js-nav').toggleClass('-active');
  $('#js-wrap').toggleClass('-disable');
  return false;
});
