import $ from "jquery";

const navPosition = $('#js-nav').offset().top;
const navHeight = $('#js-nav').outerHeight();
$(window ).on('scroll', function() {
  let deviceWidth = $(window).width();
  if(deviceWidth >= 768){
	  if($(this).scrollTop() > navPosition) {
		  $('body').css('padding-top', navHeight);
		  $('#js-nav').addClass('-fixed');
	  } else {
		  $('body').css( 'padding-top', 0);
		  $('#js-nav').removeClass('-fixed');
	  }
  }
});
